<template>
  <div class="flexCol alignItems">
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>商品管理</el-breadcrumb-item>
        <el-breadcrumb-item>积分商城</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="content_li">
        <div class="top-search">
          <div class="flexRow justifyContentSpac">
            <div class="flexRow">
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">商品名称：</div>
                <el-input v-model="search.commodityName" placeholder="搜索"></el-input>
              </div>
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">商品状态：</div>
                <el-select v-model="search.commodityStatus" placeholder="请选择">
                  <el-option
                      v-for="item in commodityStatus"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="flexRow alignItems margin-btn">
                <div class="search-title">所需积分：</div>
                <el-input-number type="number" :step-strictly="true" :controls="false" :step="1"
                                 v-model="search.startIntegral" placeholder="开始积分"></el-input-number>
              </div>
              <div class="flexRow alignItems margin-btn">
                <div class="search-title between-text">至</div>
                <el-input-number type="number" :step-strictly="true" :controls="false" :step="1"
                                 v-model="search.endIntegral" placeholder="结束积分"></el-input-number>
              </div>
              <el-button type="primary" @click="searchData" style="margin-left:20px;">搜索</el-button>
              <el-button @click="initSearch" style="margin-left:20px;">重置</el-button>
            </div>
            <div class="flexRow">
              <el-button type="primary" v-if="integralGoodsAdd" @click="goAdd">新增商品</el-button>
            </div>
          </div>
        </div>
        <div class="table-content">
          <el-table
              border
              :data="tableList"
              v-loading="tableLoading"
              style="width: 100%"
              :header-cell-style="this.utils.getRowClass"
          >
            <el-table-column align="center" type="index" label="序号" width="80"></el-table-column>
            <el-table-column align="center" prop="commodityName" label="商品名称" min-width="150"
                             :show-overflow-tooltip="true"></el-table-column>
            <el-table-column align="center" label="商品图片">
              <template slot-scope="scope">
                <img @click="handleSee(scope.row.commodityImgs[0])" class="icon-img" :src="scope.row.commodityImgs[0]"/>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="typeName" label="商品类型"></el-table-column>
            <el-table-column align="center" prop="templateTitle" label="运费模板"></el-table-column>
            <el-table-column align="center" prop="points" label="兑换所需积分"></el-table-column>
            <el-table-column align="center" label="商品状态">
              <template slot-scope="scope">{{ scope.row.commodityStatus  | getCommodityStatus }}</template>
            </el-table-column>
            <el-table-column align="center" prop="createUserName" label="创建人"></el-table-column>
            <el-table-column align="center" prop="updateUserName" label="最后修改人"></el-table-column>
            <el-table-column align="center" label="操作" min-width="150">
              <template slot-scope="scope">
                <div class="flex flex_row flex_ju_center">
                  <div class="border_center_left" v-if="integralGoodsUpd" @click="edit(scope.row)">编辑</div>
                  <div class="border_center_left" v-if="integralGoodsShelves" @click="changeStatus(scope.row)">
                    {{ scope.row.commodityStatus === 1 ? '上架' : '下架' }}
                  </div>
                  <div class="border_center_left" v-if="integralGoodsDel" @click="del(scope.row.id)">删除</div>
                  <div class="border_center_left" @click="details(scope.row)">详情</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <div class="flexRow justifyContentSpac" style="margin-top:20px">
            <div></div>
            <el-pagination
                @size-change="handleSizeChange"
                background
                layout="total, prev, pager, next,sizes"
                :total="total"
                :page-sizes="[10, 15, 20, 30, 50, 100]"
                :page-size="pageSize"
                @current-change="changePage"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="dialogImgVisible">
      <div class="flexRow justifyContentCenter alignItems">
        <img width="70%" :src="dialogImgUrl" alt=""></div>
    </el-dialog>
  </div>
</template>

<script>
let _this;
export default {
  data() {
    _this = this;
    return {
      search: {
        commodityName: "",
        commodityStatus: null,
        endIntegral: null,
        startIntegral: null,
      },
      input: "",
      tableList: [],
      options: [],
      value: "",
      dialogVisible: false,
      tableLoading: false,
      upData: {
        description: "",
        name: ""
      },
      allState: [
        {label: '否', value: 0},
        {label: '是', value: 1}
      ],
      commodityStatus: [
        {label: '下架', value: 1},
        {label: '上架', value: 2}
      ],
      integralGoodsAdd: false,
      integralGoodsDel: false,
      integralGoodsUpd: false,
      integralGoodsShelves: false,
      total: 0,
      page: 1,
      pageSize: 10,
      dialogImgVisible: false,
      dialogImgUrl: ""
    };
  },
  filters: {
    getState(value) {
      const data = _this.allState.filter(item => item.value == value);
      if (data && data.length > 0) {
        return data[0].label;
      }
    },
    getCommodityStatus(value) {
      const data = _this.commodityStatus.filter(item => item.value == value);
      if (data && data.length > 0) {
        return data[0].label;
      }
    },
  },
  mounted() {
    this.getMenuBtn();
    this.getData();
  },
  methods: {
    getData() {
      let _this = this;
      _this.tableLoading = true;
      const requestData = {
        page: _this.page,
        pageSize: _this.pageSize,
        // commodityName: _this.search.commodityName,
        // commodityStatus: _this.search.commodityStatus,
        // endIntegral: _this.search.endIntegral,
        // startIntegral: _this.search.startIntegral
      }

      // return
      // 过滤筛选
      const filterData = JSON.parse(JSON.stringify(this.search));
      for (let key in filterData) {
        if (filterData[key]) {
          requestData[key] = filterData[key];
        }
      }

      _this.$api
          .getIntegral(requestData)
          .then(res => {
            if (res.data.code == 200) {
              _this.total = res.data.data.total;
              _this.tableList = res.data.data.records;
              _this.tableList.forEach(item => {
                item.commodityImgs = item.commodityImgs.split(",");
              });
              _this.tableLoading = false;
            }
          }).catch(() => {
        _this.tableLoading = false;
      });
    },
    // 获取按钮权限
    getMenuBtn() {
      let _this = this;
      const list = JSON.parse(localStorage.getItem("jurisdictionList"));
      list.forEach((v) => {
        if (v == "integralGoods:add") {
          _this.integralGoodsAdd = true;
        }
        if (v == "integralGoods:del") {
          _this.integralGoodsDel = true;
        }
        if (v == "integralGoods:upd") {
          _this.integralGoodsUpd = true;
        }
        if (v == "integralGoods:shelves") {
          _this.integralGoodsShelves = true;
        }
      })
    },
    changePage(e) {
      let _this = this;
      _this.page = e;
      _this.getData();
    },
    handleSizeChange(e) {
      let _this = this;
      _this.page = 1;
      _this.pageSize = e;
      _this.getData();
    },
    initSearch() {
      let _this = this;
      _this.search = {
        commodityName: "",
        commodityStatus: null,
        endIntegral: null,
        startIntegral: null,
      }
      _this.page = 1;
      _this.getData();
    },
    searchData() {
      let _this = this;
      _this.page = 1;
      _this.getData();
    },
    // 浏览图片
    handleSee(e) {
      let _this = this;
      _this.dialogImgUrl = e;
      _this.dialogImgVisible = true;
    },
    goAdd() {
      let _this = this;
      _this.$router.push("/addIntegralGoods?type=add");
    },
    details(e) {
      let _this = this;
      _this.$router.push(
          "/integralGoodsDetails?data=" +
          encodeURIComponent(JSON.stringify(e))
      );
    },
    edit(e) {
      let _this = this;
      _this.$router.push(
          "/addIntegralGoods?type=edit&goodsId=" + e.id
      );
    },
    del(id) {
      let _this = this;
      _this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info"
      })
          .then(() => {
            _this.$api.delIntegral({id}).then(res => {
              if (res.data.code == 200) {
                if (res.data.data) {
                  _this.$message.success('删除成功');
                  _this.getData();
                } else {
                  _this.$message.error('删除失败');
                }
              }
            });
          })
          .catch(() => {
          });
    },
    // 上下架
    changeStatus(val) {
      const data = JSON.parse(JSON.stringify(val));
      const title = data.commodityStatus === 1 ? '上架' : '下架'

      let _this = this;
      _this.$confirm(`此操作将${title}该数据, 是否继续?`, '提示', {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info"
      })
          .then(() => {
            _this.$api.statusCommodityInfoById({id: val.id}).then(res => {
              if (res.data.code == 200) {
                if (res.data.data) {
                  _this.$message.success(`${title}成功`);
                  _this.getData();
                } else {
                  _this.$message.error(`${title}失败`);
                }
              }
            });
          })
          .catch(() => {

          });

    },
    handleClose() {
      let _this = this;
      _this.upData = {
        description: "",
        name: ""
      };
      _this.dialogVisible = false;
    },

  }
};
</script>
<style scoped lang="less">
.between-text {
  margin: 0 10px;
}
</style>

